@charset "utf-8";
//-----------------フッター //

footer {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 0;
  line-height: 1.3;
  background: $bgFooter;
}

footer nav {
  width: 100%;
}

.footNavi {
  width: 100%;
  margin: auto;
  background: none;
}
.footNavi li {
  display: block;
  margin: 0 auto;
  padding: 0;
  border-bottom: 1px solid darken($colorTextReversal, 10%);
}
.footNavi li a {
  position: relative;
  display: block;
  padding: 1.1em;
  transition: .4s;
  text-decoration: none;
  color: $colorText;
  background: $bgFooter;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 3%;
    bottom: 0;
    left: auto;
    width: 8px;
    height: 8px;
    margin: auto;
    transform: rotate(45deg);
    border-top: 1px solid $colorText;
    border-right: 1px solid $colorText;
  }
  &:hover {
    // background: desaturate($color01, 20%);
    transition: .4s;
  }
}

@if $spMode == false {
  @media screen and  ( min-width : $viewTablet+px ) {

    footer .flexBox {
      justify-content: flex-start;
    }
    .footLogo {
      width: 30%;
      background: $color01;
    }

    .footNavi {
      margin: 25px 0 5px;
      text-align: center;
    }

    .footNavi li {
      position: relative;
      display: inline-block;
      margin-bottom: 0em;
      border-bottom: 0;

      a {
        position: relative;
        padding: 0 2em;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;
        letter-spacing: 0.05em;
        color: $colorText;
        background: none;

        &:hover {
          text-decoration: underline;
          background: none;
        }
      }
    }

    .footNavi li:not(:first-child):not([class]) a:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      height: 70%;
      margin: auto;
      background: lighten($colorText, 0%);
    }

    .footNavi li.parent {
      position: relative;
      display: block;
      padding-left: 0;
      text-align: left;

      &:before {
        content: none;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 5px;
        width: .5em;
        height: 2px;
        margin: auto;
        transform: none;
        border: 0;
        background: lighten($colorText, 10%);
      }
      a {
        font-size: 120%;
      }
    }

    .footNavi li.parent ~ li:not(.parent) {
      display: block;
      float: none;
      margin-bottom: 5px;
      padding-left: 1.5em;
      text-align: left;

      a {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: .5em;
          height: 2px;
          margin: auto;
          background: lighten($colorText, 10%);
        }
      }
    }
    .footNavi li a:after {
      content: none;
    }
  }
}

.copy {
  display: block;
  margin: 0 auto 0;
  //border-top: 1px solid #286399;//
  padding: .5em 0;
  text-align: center;
  background: $bgCopy;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000;
  }
}

#toTop {
  position: fixed;
  z-index: 9999;
  right: 2%;
  bottom: 2%;
  display: inline-block;
  //border: 1px solid #fff;//
  width: 70px;
  height: 70px;
  white-space: nowrap;
  // overflow: hidden;
  text-indent: 100%;
  // border-radius: 50%;
  background: $colorTop;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    width: 50px;
    height: 50px;

    &:after {
      width: 15px;
      height: 15px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 5%;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    transition: .2s;
    transform: rotate(45deg);
    border-top: 2px solid $colorTopArrow;
    border-left: 2px solid $colorTopArrow;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin: auto;
    // background: $colorTop;
    border: 2px solid $colorTopArrow;
    // border-radius: 50%;
  }
  &:hover {
    transform: scale(1.3);
    background: transparent;
    &:after {
      transform: rotate3d(0, -1, 0, 180deg) scale(1.1, 1) rotate(45deg);
      border-color: $colorTopArrow;
    }
    &:before {
      animation: rotation 4s infinite linear;
      border-style: dashed;
      border-color: $colorTopArrow;
      border-radius: 50%;
    }
  }
  img {
    width: 40px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@if $spMode == true {
  #toTop {
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    padding: 0;
    border-radius: 0;
  }
}
