@charset "utf-8";


/*/-----------------TOPページ*/

//----------------- ヘッダー //

header {
  width: 100vw;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    position: fixed;
  }
  .btnStyle {
    width: 200px;
  }
  .headInfo {
    @media screen and (min-width : $viewTablet+px) {
      margin-right: 2%;
      nav {
        padding-right: 3%;
      }
    }
  }
}

//----------------- メインヴィジュアル //

.mainImage {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
  background: $color03;
  .slide {
    max-width: 1350px;
    margin: auto;
    img {
      width: 100%;
    }
  }
}

.mainCatch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  // max-width: $containerSize+px;
  max-width: 1350px;
  margin: auto;
  figure {
    animation: catchfade 1.5s ease 0s 1 forwards;
    opacity: 0;
    @for $i from 1 through 5 {
      &:nth-of-type(#{$i}) {
        animation-delay: #{$i * 300}ms;
        img {
          animation-delay: #{$i * 100}ms;
        }
      }
    }
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    // bottom: 0;
    left: 0;
    width: 100%;
    // max-width: $copyWidth+px!important;
    // height: $copyHeight / $copyWidth * 100% * 1;
    // max-height: $copyHeight+px;
    margin: auto!important;
    animation: fuwafuwa 2.5s ease 1s infinite normal;
    opacity: 1;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      top: auto;
      bottom: 35%;
    }
    &.noAnime {
      animation: none;
    }
  }
}

@keyframes catchfade {
  0% {
    // transform: scale(0);
    opacity: 0;
  }
  100% {
    // transform: scale(1);
    opacity: 1;
  }
}
//
//
// @keyframes pager {
//   0% {
//     width: 0;
//   }
//   100% {
//     width: 100%;
//   }
// }
//
// .mainImage {
//   .slideImg {
//     img {
//       transition: 5s;
//       transform: scale(1.1);
//     }
//     &.slick-active {
//       img {
//         transform: scale(1);
//       }
//     }
//     &.firstImg {
//       img {
//         animation: firstimg 5s ease forwards;
//       }
//     }
//   }
// }
//
// @keyframes firstimg {
//   0% {
//     transform: scale(1.1);
//     // transform: scale(0);
//   }
//   100% {
//     transform: scale(1);
//     // transform: scale(1);
//   }
// }

// #toTop {
//   width: 78px;
//   height: 78px;
//   transition: .4s;
//   border-radius: 0;
//   background: url(../images/top_sp.png) no-repeat center / 100%;
//   &:hover {
//     transition: .4s;
//     border-radius: 50%;
//   }
// }

@keyframes fuwafuwa {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.scrollPalla {
  @media screen and (min-width : $viewTablet+px) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      transition: all 1s ease-out;
      background: url(../images/mv_bg.png) no-repeat top 10% center / contain;
    }
    img {
      transition: all 1s ease-out;
    }
    &.active {
      img {
        top: 10%;
        transition: all 1s ease-out;
        &.noAnime {
          top: -13%;
        }
      }
      &:before {
        top: -13%;
        transition: all 1s ease-out;
      }
    }
  }
}

.scrollBtn {
  a {
    position: absolute;
    z-index: 2;
    top: -20%;
    right: 0;
    left: 0;
    display: inline-block;
    margin: auto;
    text-align: center;
    text-decoration: none;
    color: $colorTextReversal;
  }
  a {
    padding-top: 30px;
    font-size: 90%;
    text-align: center;
  }
  a span {
    position: absolute;
    top: 0;
    left: 50%;
    box-sizing: border-box;
    width: 20px;
    height: 35px;
    margin-left: -10px;
    border: 2px solid #fff;
    border-radius: 50px;
  }
  a span::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 50%;
    box-sizing: border-box;
    width: 2px;
    height: 4px;
    margin-left: -1px;
    animation: sdb 2s infinite;
    border-radius: 0%;
    background-color: #fff;
  }

  @keyframes sdb {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

.scrollBtn {
  position: relative;
  height: 70px;
  background: $color02;
  @media screen and (min-width : 375px) {
    height: 100px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-width: 88px 188px 0 188px;
    border-style: solid;
    border-color: $color03 transparent transparent transparent;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      border-width: 50px 120px 0 120px;
    }
  }
}

//----------------- サイド //
.sideBtn {
  position: fixed;
  z-index: 800;
  right: 0;
  bottom: 18%;
  transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transform: translateX(100%);
  text-align: right;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    width: 30%;
  }
  &.fixed {
    transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transform: translateX(0);
  }
  &:hover {
    transform: translateX(10px);
    a img {
      opacity: 1;
    }
  }
}

//----------------- メイン //

.topContents {
  // margin-top: 103px;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    margin-top: 85px;
    font-size: 3.3vw;
    h2 {
      font-size: 8vw;
      span {
        font-size: 45%;
      }
    }
    p,figcaption {
      font-size: 3.3vw;
    }
  }
  @media screen and (min-width: $viewSP+px) {
    a[href^="tel:"] {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.sec01 {
  position: relative;
  margin-bottom: 0;
  padding: 3% 0 5%;
  background: $color02;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    padding: 8% 0;
  }
  figcaption {
    margin: 1em auto;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: .05em;
  }
  .columnRow:first-child {
    margin-bottom: 2%;
  }
}

.sec02 {
  margin-bottom: 0;
  padding: 5% 0 2%;
  background: $color03;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    padding: 8% 0 5%;
  }

  h2 {
    margin-bottom: 1.7em;
    color: $colorTextReversal;
    span {
      color: $colorTextReversal;
    }
  }

  .bgWhite {
    position: relative;
    overflow: hidden;
    margin-bottom: 7%;
    padding: 4% 5%;
    border-radius: 5px;
    background: #fff;
    // @media screen and (min-width : $viewTablet+px) {
    //   &:nth-of-type(1) {
    //     &:after {
    //       content: '';
    //       position: absolute;
    //       right: 0;
    //       bottom: 0;
    //       width: 43%;
    //       height: 100%;
    //       margin: auto;
    //       background: url(../images/sec02_img001.png) no-repeat center bottom / 100%;
    //     }
    //   }
    //   &:nth-of-type(2) {
    //     &:after {
    //       content: '';
    //       position: absolute;
    //       bottom: 0;
    //       left: 0;
    //       width: 45%;
    //       height: 100%;
    //       margin: auto;
    //       background: url(../images/sec02_img001.png) no-repeat center bottom / 100%;
    //     }
    //   }
    //   &:nth-of-type(3) {
    //     &:after {
    //       content: '';
    //       position: absolute;
    //       right: 0;
    //       bottom: 0;
    //       width: 45%;
    //       height: 100%;
    //       margin: auto;
    //       background: url(../images/sec02_img001.png) no-repeat center bottom / 100%;
    //     }
    //   }
    // }
    p {
      line-height: 2;
    }
    p:not(:last-child) {
      margin-bottom: 2em;
    }
  }
  h3 {
    position: relative;
    margin-bottom: .8em;
    padding: .3em 0 .3em 3.8em;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: 0;
    color: $color04;
    border-bottom: 1px solid #081c68;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      font-size: 4.5vw;
    }
    &:before {
      content: "0" counter(number);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      font-size: 250%;
      font-weight: 900;
      font-style: italic;
      counter-increment: number 1;
      color: #eee;
    }
    &.-textRight {
      padding-right: 3.8em;
      padding-left: 0;
      &:before {
        right: 0;
        left: auto;
      }
    }
  }
}

.-pt1 {
  padding-top: 1em!important;
}
.-pb1 {
  padding-bottom: 1em!important;
}

.sec03 {
  margin-bottom: 0;
  padding: 0 0 5%;
  background: $color03;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    padding: 0 0 10%;
  }
  h2 {
    margin-bottom: 1.5em;
    font-size: 30px;
    color: $colorTextReversal;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      font-size: 4.5vw;
    }
  }
  figcaption {
    min-height: 72px;
    margin: 1.5em auto .5em;
    font-size: 22px;
    font-weight: 900;
    line-height: 1.3;
    letter-spacing: .1em;
    color: #1e76b6;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      min-height: inherit;
      font-size: 3.8vw;
    }
  }
  .bgBlue {
    padding: 4% 0;
    border-radius: 7px;
    background: #fff;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      margin-bottom: 5%;
    }
    p {
      padding: 4% 7%;
      line-height: 2;
      letter-spacing: .0em;
    }
  }
}

.sec04 {
  margin: auto;
  margin-bottom: 0;
  padding: 7% 0 0;
  background: $color01;
  .inner {
    max-width: 3000px;
    padding: 0;
  }
  .columnBlock:last-child {
    @media screen and (min-width : $viewTablet+px) {
      max-width: 490px;
      padding-left: 1.5%;
    }
  }
  .columnBlock:first-child {
    figure {
      img {
        width: 100%;
      }
    }
  }
  p {
    font-weight: bold;
    &:not(:last-child) {
      margin-bottom: 8%;
      @media screen and  ( max-width : ($viewTablet - 1)+px ) {
        margin-bottom: 1.5em;
      }
    }
    svg {
      margin-right: .3em;
      transform: scale(-1,1);
      vertical-align: sub;
      @media screen and  ( max-width : ($viewTablet - 1)+px ) {
        vertical-align: inherit;
      }
    }
    &.borderTB {
      padding: .8em .3em;
      border: 1px solid $colorTextReversal;
      border-width: 1px 0;
      @media screen and  ( max-width : ($viewTablet - 1)+px ) {
        width: 95%;
        margin: auto;
      }
    }
    &.-textLarge {
      font-size: 20px!important;
      line-height: 1.8;
      letter-spacing: .04em;
      @media screen and  ( max-width : ($viewTablet - 1)+px ) {
        padding: 0 .5em;
        font-size: 3.6vw!important;
      }
    }
  }
}

.sec05 {
  margin-bottom: 0;
  padding: 3% 0;
  background: $color01;
  p {
    letter-spacing: .04em;
    &.-textSmall {
      font-size: 14px!important;
      @media screen and  ( max-width : ($viewTablet - 1)+px ) {
        font-size: 3vw!important;
      }
    }
  }
  .columnRow {
    margin-bottom: 3%;
  }
  .borderWrap {
    p {
      line-height: 2;
      letter-spacing: 0;
    }
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
}

.borderWrap {
  position: relative;
  z-index: 1;
  padding: 4% 4%;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: auto;
    border: 1px solid $colorTextReversal;
    border-color: #fff transparent transparent #fff;
    border-radius: 13px;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    width: 0%;
    height: 0%;
    margin: auto;
    border: 1px solid $colorTextReversal;
    border-color: transparent #fff #fff transparent;
    border-radius: 13px;
  }
  &.active {
    &:after {
      width: 100%;
      height: 100%;
      transition: 1s;
      animation: borderAfter 2s ease 1s 1 forwards;
    }
    &:before {
      width: 100%;
      height: 100%;
      transition: 1s;
      animation: borderBefore 2s ease 1s 1 forwards;
    }
  }
}

@keyframes borderBefore {
  0% {
    border-color: $colorTextReversal;
  }
  100% {
    // transform: translate(-10px, -10px);
    border-color: $colorTextReversal;
  }
}

@keyframes borderAfter {
  0% {
    border-color: $colorTextReversal;
  }
  100% {
    // transform: translate(10px, 10px);
    border-color: $colorTextReversal;
  }
}

.redBox {
  padding: .5em 1em;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  letter-spacing: .04em;
  color: $colorTextReversal;
  background: #e83132;
}

.solutionBox {
  max-width: 300px;
  margin: auto;
  margin-bottom: 5%;
  padding: 0 0 10%;
  border-radius: 7px;
  background: #fff;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    max-width: 600px;

  }

  figcaption {
    margin: 1em auto;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: .04em;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      font-size: 3.8vw;
    }
  }

  p {
    padding: 0 7%;
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: .06em;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      font-size: 3vw;
    }
  }
}

.sec06 {
  margin-bottom: 0!important;
  padding: 5% 0;

  .leadText {
    margin-bottom: 2.2em;
  }
}

//----------------- フォーム //

.c-form {
  .bgGray {
    margin-bottom: 5%;
    padding: 6% 8%;
    background: #f2f2f2;
  }

  th {
    font-weight: bold;
    vertical-align: top;
    letter-spacing: .04em;
    color: #000;
    @media screen and (min-width : $viewTablet+px) {
      width: 30%;
    }
  }
  .hissu {
    vertical-align: super;
    color: red;
  }
  input:not([type=checkbox]), select, textarea {
    width: 100%;
    padding: .3em;
    border: 1px solid #666;
    border-radius: 4px;
    &.familyName {
      width: calc(50% - 2em);
      margin-right: 1em;
    }
    &.firstName {
      width: calc(50% - 2em);
    }
  }
  textarea {
    min-height: 200px;
  }
  .c-form__button {
    text-align: center;
  }
  button {
    width: 250px;
    padding: .5em 1em;
    font-size: 20px;
    font-weight: bold;
    transition: .4s;
    color: $colorTextReversal;
    border: 0;
    border-radius: 13px;
    background: $color01;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      width: auto;
    }
    img {
      margin-top: -2px;
      margin-left: .5em;
      vertical-align: middle;
    }
    &:hover {
      transition: .4s;
      opacity: .7;
    }
  }
  input[type=checkbox] {
    margin-right: .8em;
    vertical-align: middle;
  }
}

.privacyWrapper {
  margin-bottom: 3%;
  .privacyContent {
    padding: .8em .3em .8em 1.5em;
    border: 1px solid #000;
    border-radius: 9px;
    > div {
      overflow-y: scroll;
      height: 150px;
    }
  }
}



// @for $i from 100 through 1900 {
//   .retina#{$i} {max-width: #{$i}px!important;}
// }


//----------------- フッター //

footer {
  padding: 4% 0 1%;
  // p {
  //   a + a {
  //     margin-left: 6%;
  //   }
  // }
  nav {
    @media screen and (min-width : $viewTablet+px) {
      margin: 3% auto;
    }
  }
}


/*/-----------------下層*/


/*/-----------------PC＆SP*/

@media only screen and (min-width: $viewTablet+px) {
  .pcOnly {display: block;}
  img.pcOnly {display: inline;}
  .spOnly {display: none!important;}
}
@media only screen and (max-width: ($viewTablet - 1)+px) {
  .pcOnly { display: none!important;}
  .spOnly {display: block;}
  img.spOnly { display: inline;}
}
@media only screen and (min-width: $header_breakPoint+px) {
  header {
    .pcOnly {display: block;}
    img.pcOnly {display: inline;}
    .spOnly {display: none!important;}
  }
}
@media only screen and (max-width: ($header_breakPoint - 1)+px) {
  header {
    .pcOnly { display: none!important;}
    .spOnly {display: block;}
    img.spOnly { display: inline!important;}
  }
}
