@charset "utf-8";

header {
  h1 {
    font-weight: bold;
    @media screen and (min-width : $viewTablet+px) {
      width: 29%;
    }
  }
}

header {
  position: relative;
  z-index: 300;
  padding: 0;
  background: none;
  background: $bgHeader;
}

header .inner {
  max-width: $headerSize;
  padding: 0;
}

.headInfo {
  display: flex;
  width: 73%;
  margin-right: 2%;
  // display: inline-block;
  // float: right;
  // width: 55%;
  text-align: right;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

@if $spMode == true {
  .headInfo {
    width: auto;
    margin-top: 1.3em;
    margin-right: 58px;
  }
} @else {
  @media screen and  ( max-width : ($header_breakPoint - 1)+px ) {
    .headInfo {
      position: absolute;
      top: 0;
      right: 5px;
      bottom: 0;
      display: block;
      width: auto;
      height: fit-content;
      margin: auto;
      padding: 20px 10px;
    }
  }
}

header h1 {
  display: inline-block;
  width: auto;
  max-width: $logo_width+px;
  margin: 20px 15px 18px;
  margin-left: 7%;
  vertical-align: middle;
  @media screen and  ( max-width : ($header_breakPoint - 1)+px ) {
    margin: 10px 10px!important;
  }
  > img {
    width: auto;
    max-width: 100%;
  }
}

///-----------------スマホナビ//

header .ac_menu {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: inline-block;
  width: 46px;
  height: 46px;
  margin: auto;
  cursor: pointer;
  border-radius: 4px;
  background: transparent;

  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 70%;
    height: 5px;
    margin: auto;
    transition: .4s;
    border-radius: 10px;
    background: $color01;

    &:before, &:after {
      content: '';
      position: absolute;
      top: -10px;
      right: 0;
      bottom: auto;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      margin: auto;
      transition: .3s;
      border-radius: 10px;
      background: $color01;
    }
    &:after {
      top: auto;
      bottom: -10px;
    }
  }
  &.active {
    span {
      transition: .3s;
      background: transparent;

      &:before, &:after {
        top: 0;
        bottom: 0;
        transition: .3s;
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

header nav .navi {
  position: absolute;
  z-index: 9999;
  top: 149%;
  left: 0;
  display: none;
  width: 100%;
  li {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;

    a {
      position: relative;
      display: block;
      width: 100%;
      padding: 1.3em 1em;
      transition: .4s;
      text-align: left;
      text-decoration: none;
      color: $colorTextReversal;
      border-bottom: 1px solid darken($colorTextReversal, 10%);
      background: $color01;

      span {
        margin-left: .8em;
        font-size: 80%;
      }
      &:hover {
        transition: .4s;
        text-decoration: none;
        background: lighten($color01, 10%);
      }
    }
  }
}

header .navi li.has-child {
  > a {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      width: 8px;
      height: 8px;
      margin: auto;
      transition: .4s;
      transform: rotate(45deg);
      border-right: 2px solid $colorTextReversal;
      border-bottom: 2px solid $colorTextReversal;
    }
  }
  > ul.subMenu {
    display: none;
    margin: 0;
    padding: 0;

    a {
      padding-left: 1em;
      color: $colorTextReversal;
      background: darken($color01, 10%);
      &:hover {
        transition: .4s;
        text-decoration: none;
        background: desaturate($color01, 20%);
      }
    }
  }
  &.pullOpen > a:after {
    transition: .4s;
    transform: rotate(225deg);
  }
}

@if $spMode == false {
  @media screen and ( min-width : $header_breakPoint+px ) {

    header .ac_menu {
      display: none;
    }

    header nav {
      // background: #ddd;
    }

    header nav .navi {
      position: relative;
      top: auto;
      left: auto;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      margin: 15px auto;
      text-align: center;

      > li {
        position: relative;
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0;
        text-align: center;

        > a {
          padding: .7em 1em;
          font-size: 100%;
          font-weight: normal;
          font-weight: bold;
          line-height: 1.2;
          transition: .7s;
          text-align: center;
          text-decoration: none;
          letter-spacing: -0.025em;
          color: #000;
          border: 0;
          background: none;

          span {
            display: block;
            margin: 0 auto;
            font-size: 80%;
            text-align: center;
            color: $color01;
          }

          &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 90%;
            height: 2px;
            margin: auto;
            transition: .4s;
            transform: scale(0);
            background: $color01;
          }
          &:hover {
            transition: .7s;
            color: $color01;
            background: none;
            &:after {
              transition: .4s;
              transform: scale(1);
            }
            span {
              animation: gnvahover .4s ease 0s .4 normal;
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &:not(:first-child):before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          height: .8em;
          margin: auto;
          background: #000;
        }
      }
    }


    ///-----------------サブメニュー//

    header .clearfix,
    header nav .navi {
      overflow: inherit;
    }

    header .navi li.has-child > a:after {
      content: none;
    }

    header .navi li.has-child {
      > a {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: auto;
          right: 5%;
          bottom: 17%;
          left: auto;
          width: 8px;
          height: 8px;
          margin: auto;
          transition: .3s;
          transform: rotate(45deg);
          border-right: 2px solid $colorTextReversal;
          border-bottom: 2px solid $colorTextReversal;
          background: none;
        }
      }
      > ul.subMenu {
        position: absolute;
        top: 30%;
        right: 0;
        left: -10%;
        display: block!important;
        visibility: hidden;
        width: 120%;
        margin: auto;
        padding: 0;
        transition: .2s linear;
        opacity: 0;

        > li {
          display: block;
          width: 100%;
          margin: auto;
          text-align: center;
          background: $color01;

          > a {
            display: inline-block;
            width: 100%;
            padding: .8em .5em;
            font-size: 90%;
            text-align: center;
            text-decoration: none;
            color: $colorTextReversal;
          }
          &:hover {
            transition: .4s;
            background: desaturate($color01, 20%);
          }
          &:last-child {
            a {
              border-bottom: 0;
            }
          }
        }
      }
      &:hover {
        > a {
          &:after {
            transition: .3s;
            transform: rotate(225deg);
            background: none;
          }
        }
        ul.subMenu {
          top: 100%;
          visibility: visible;
          transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
          opacity: 1;
        }
      }
    }
  }
}

@keyframes gnvahover {
  0% {
    transform: translateY(30px);
    opacity: 0;
    // transform: scale(0);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    // transform: scale(1);
  }
}
