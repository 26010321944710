@charset "utf-8";
///-----------------見出し//

h1.underTitle {
  @include bgSlash(4);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 1.5em 1em;
  font-family: $fontMincho;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  color: $colorText;
  background: $colorText;
  background-color: $color01;

  span {
    display: block;
    margin: auto;
    font-family: $baseFont;
  }
}

main {
  h2 {
    position: relative;
    margin: 0 auto 1em;
    padding: 0 0;
    font-weight: 900;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0;
    color: #000;

    span {
      display: block;
      margin: .5em auto;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: .08em;
    }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   right: 0;
    //   bottom: 15%;
    //   left: 0;
    //   width: 120px;
    //   height: 3px;
    //   margin: auto;
    //   border-bottom: 2px solid $colorTextReversal;
    // }
  }
  //
  // h3 {
  //   position: relative;
  //   display: block;
  //   margin-bottom: 1em;
  //   padding: .5em;
  //   font-weight: bold;
  //   line-height: 1;
  //   text-align: left;
  //   letter-spacing: 0.04em;
  //   color: $colorText;
  //
  //   span {
  //     vertical-align: baseline;
  //   }
  //   &:before,
  //   &:after {
  //     content: none;
  //     // position: absolute;
  //     // bottom: 0;
  //     // left: 0;
  //     // width: 100%;
  //     // border-bottom: 3px solid rgba($color02, 1);
  //   }
  //   // &:after {
  //   //   width: 20%;
  //   //   border-bottom: 3px solid $color01;
  //   // }
  // }
  //
  // h4 {
  //   position: relative;
  //   margin-bottom: 1em;
  //   padding: .5em .8em;
  //   font-weight: bold;
  //   line-height: normal;
  //   text-align: left;
  //   letter-spacing: 0;
  //   color: $colorText;
  //   // border-bottom: 1px solid $color01;
  //   border-left: 4px solid $color01;
  //   background: rgba($color01, .25);
  //
  //   span {
  //     vertical-align: baseline;
  //   }
  // }
  //
  // h5 {
  //   position: relative;
  //   margin-bottom: .5em;
  //   padding: .2em 0 .3em;
  //   font-weight: bold;
  //   line-height: normal;
  //   text-align: left;
  //   letter-spacing: 0;
  //   color: $colorText;
  //
  //   &:after {
  //     content: '';
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     width: 32px;
  //     height: 2px;
  //     margin: auto;
  //     background: $color01;
  //   }
  // }
  //
  // h6 {
  //   position: relative;
  //   margin-bottom: .3em;
  //   padding-left: 1.3em;
  //   font-weight: bold;
  //   line-height: normal;
  //   text-align: left;
  //   letter-spacing: 0;
  //   color: $colorText;
  //
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     width: 8px;
  //     height: 8px;
  //     margin: auto;
  //     background: $color01;
  //     // border-radius: 50%;
  //   }
  // }
  h1,h2,h3,h4,h5,h6 {
    a,a:hover {
      text-decoration: none;
      color: inherit;
    }
    img {
      vertical-align: middle;
    }
  }
}

.numberTitle span {
  position: relative;
  display: inline;
  padding-left: 1.5em;
  font-size: 100%;
  vertical-align: text-bottom;

  &:after {
    content: "0" counter(number) ;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    counter-increment: number 1;
  }
}

//----------------- カラム//

.columnWrap {
  display: block;
  overflow: hidden;
  width: 100%;
  table-layout: fixed;

  .columnRow {
    position: relative;
    display: block;
    clear: both;
    overflow: hidden;
    width: 100%;
    padding: 0;

    > .columnBlock {
      display: inline-block;
      float: left;
      width: 48.5%;
      margin: auto;
      margin-top: 1.3%;
      margin-left: 1.5%;
      padding: 0;
      text-align: left;

      p {
        text-align: left;
      }
      figure {
        display: block;
        margin: auto;
      }
    }
    > .columnBlock:first-child {
      margin-left: 0!important;
    }
    > .columnBlock:last-child {
      margin-right: 0!important;
    }
    &.-vertical > .columnBlock {
      position: relative;
      float: none;
      vertical-align: middle;
    }
    &.-reversal > .columnBlock {
      position: relative;
      float: right;
    }
    .columnBlock.last + .columnBlock {
      clear: both;
      margin-left: 0;
    }
    &.-reversal > .columnBlock:first-child {
      margin-left: 1%!important;
    }
    &.-reversal > .columnBlock:last-child {
      margin-left: 0;
    }
    &.-wide > .columnBlock {
      width: 46.5%;
      margin-left: 6.5%;
    }

    @for $i from 2 through 8 {
      &.columnBlocks-#{$i} > .columnBlock {
        $blockMargin: #{($i - 1) * 1.52 + 0%};
        width: calc((100% - #{$blockMargin}) / #{$i});
        margin-left: 1.5%;
        &:nth-child(#{$i}n) + .columnBlock {
          clear: both;
          margin-left: 0;
        }
      }
    }
  }
}

@for $i from 2 through 20 {
  .columnRow > .columnBlock.-wd#{$i*5} { width:#{$i * 5 - 1}#{'%'};}
}
.columnRow > .columnBlock.-wd100 {
  width: 100%;
}

@if $spMode == false {
  @media ( max-width : ($viewTablet - 1)+px ) {
    .columnWrap .columnRow:not(.-fix) {
      > .columnBlock {
        display: block;
        float: none;
        width: 100%;
        margin: 0 auto 15px;
        &:first-child {
          margin-left: 0!important;
        }
      }
      .-wd10, .-wd20, .-wd30, .-wd40, .-wd60, .-wd70, .-wd80, .-wd90, .-wd15, .-wd25, .-wd35, .-wd45, .-wd65, .-wd75, .-wd85, .-wd95 {
        width: 100%;
      }
      &.-sp_2 {
        > .columnBlock {
          display: inline-block;
          float: left;
          width: (100 - 3.2 + 0%) / 2;
          margin: auto;
          margin-top: 2%;
          padding: 0;
          text-align: left;

          &:nth-child(even) {
            margin-left: 1.5%;
          }
          &:nth-child(odd) {
            clear: both;
          }
        }
        @for $i from 2 through 8 {
          &.columnBlocks-#{$i} > .columnBlock {
            &:nth-child(#{$i}n) + .columnBlock {
              clear: none;
            }
          }
        }
      }
      &.-sp_3 {
        > .columnBlock {
          display: inline-block;
          float: left;
          width: (100 - 2.7 + 0%) / 3;
          margin: auto;
          margin-top: 2%;
          padding: 0;
          text-align: left;

          &:not(:nth-child(3n+1)) {
            margin-left: 1.3%;
          }
          &:nth-child(3n+1) {
            clear: both;
          }
        }
        @for $i from 2 through 8 {
          &.columnBlocks-#{$i} > .columnBlock {
            &:nth-child(#{$i}n) + .columnBlock {
              clear: none;
            }
          }
        }
      }
    }
  }
}

///-----------------フレックスボックス//

.flexBox {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
}

.flexBox.-vertical {
  align-items: center;
}

.flexBox.-reversal {
  flex-direction: row-reverse;
}

.flexBox.-flexBetween {
  justify-content: space-between;
}
@for $i from 2 through 8 {
  .flexBox.columnBlocks-#{$i} > * {
    width: calc(100% / #{$i});
  }
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    .flexBox:not(.-sp_2) {
      > * {
        display: block;
        width: 100%;
        &:not(ul) {
          margin: 20px auto;
        }
      }
    }
    .flexBox.-sp_2 {
      > * {
        width: calc(100% / 2);
      }
    }
  }
}
