@charset "utf-8";

html,
body {
  -webkit-text-size-adjust: 100%;
}

html {
  // font-size: 62.5%;
  overflow:auto;
}

body {
  overflow: hidden;
  box-sizing: border-box;
  font-family: $baseFont;
  font-size: $baseFontSize+px;
  font-weight: 400;
  line-height: 1.8;
  word-wrap: break-word;
  color: $colorText;
  background-color: $bgBody;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

  *,
  *:before,
  *:after {
    box-sizing: inherit
  }
}


a:not(.btnStyle) {
  text-decoration: underline;
  color: inherit;
  outline: none;
}

ol, ul {
  list-style: none;
}
ol > li > ul,
ul > li > ul {
  padding-top: .3em;
  padding-left: 1em;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}

section {
  clear: both;
}
a:focus, *:focus {
  outline: none;
}
a, a img {
  transition: .2s;
}
a:hover {
  text-decoration: none;
}
a:hover > img {
  cursor: pointer;
  transition: .2s;
  opacity: 0.7;
}

figure {
  margin: 0;
  padding: 0;
  text-align: center;
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    vertical-align: middle;
  }
}

figure figcaption {
  margin: 10px auto;
  text-align: left;
}
strong {
  font-weight: bold;
}
html {
  overflow: auto;
}

li,td,th,dt,dd,p,figcaption {
  text-align: inherit;
}
p {
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
  line-height: 1.6;
  text-align: left;
  letter-spacing: 0.06em;
  word-break: break-all;
}


///-----------------BASE//
html {

  // 641px以降から、100pxごとに1px大きくなる
  // @media (min-width: $viewSP+px) {
  //   font-size: calc((#{$baseFontSize - 3} / #{$baseFontSize} * 100%) + 3 * (100vw - #{$viewSP}px) / (1200 - #{$viewSP}))px;
  //   h1.underTitle { font-size: 2.5rem;}
  //   main {
  //     h2 { font-size: 1.5rem;}
  //     h3 { font-size: 1.4rem;}
  //     h4 { font-size: 1.3rem;}
  //     h5 { font-size: 1.2rem;}
  //     h6 { font-size: 1.1rem;}
  //   }
  // }
  @media (min-width: $viewTablet+px) {
    font-size: $baseFontSize+px;
    h1.underTitle {
      font-size: 34px;
      span { font-size: 20px;}
    }
    main {
      h2 {
        font-size: 40px;
        span { font-size: 16px;}
      }
      h3 {
        font-size: 28px;
        span { font-size: 16px;}
      }
      h4 {
        font-size: 20px;
        span { font-size: 14px;}
      }
      h5 {
        font-size: 19px;
        span { font-size: 80%;}
      }
      h6 {
        font-size: 18px;
        span { font-size: 80%;}
      }
    }
  }
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    main {
      h2 {
        font-size: 20px;
        // span { font-size: 70%;}
      }
      h3 {
        font-size: 18px;
        span { font-size: 80%;}
      }
      h4 {
        font-size: 16px;
        span { font-size: 80%;}
      }
      h5 {
        font-size: 15px;
        span { font-size: 80%;}
      }
      h6 {
        font-size: 14px;
        span { font-size: 80%;}
      }
    }
  }


  h1.underTitle {
    font-size: 22px;
    span { font-size: 80%;}
  }
}

.inner {
  width: 100%;
  max-width: $containerSize+px;
  margin: 0 auto;
  padding: 0 5px;
}

p {
  margin-bottom: 1em;
  line-height: 1.6;
  text-align: left;
  letter-spacing: 0;
  word-break: break-all;
}

@media screen and (min-width: $viewSP+px) {
  a[href^="tel:"] {
    text-decoration: none;
    pointer-events: none;
  }
}

main section {
  margin-bottom: 2%;
  padding: 1.5% 0;
}

main section:last-child {
  margin-bottom: 3%;
}

body.under {
  main {
    min-height: 60vh;
    @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      margin-top: 85px;
    }
  }
}
