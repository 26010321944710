@charset "utf-8";

///-----------------ボタン//

.btnStyle {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  max-width: 330px;
  margin: auto;
  padding: .8em .8em;
  font-weight: normal;
  // line-height: 1.5;
  transition: .4s;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.04em;
  color: $colorBtnText!important;
  border: 1px solid $colorBtnBorder;
  border-radius: 5px;
  background: $colorBtn_hover;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    max-width: inherit;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 5%;
    bottom: 0;
    // transform: rotate(45deg);
    // border-top: 1px solid $colorBtnText;
    // border-right: 1px solid $colorBtnText;
    width: 14.5px;
    height: 14.5px;
    margin: auto;
    // width: 5px;
    // height: 5px;
    font-size: 14px;
    font-weight: bold;
    transition: .2s;
    background: url(../images/arrow.png) no-repeat center / 100%;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    transition: all .6s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transform: translateX(0);
    background: $colorBtn;
  }

  &:hover {
    transition: .2s;
    text-decoration: none;
    color: $colorBtnText_hover!important;
    border-color: $colorBtnBorder_hover;
    background: $colorBtn_hover;

    &:before {
      content: '';
      transition: all .6s cubic-bezier(0.165, 0.840, 0.440, 1.000);
      // width: 0;
      transform: translateX(100%);
      animation: none;
      opacity: 1;
    }

    &:after {
      top: 23%;
      transition: .3s;
      border-color: $colorBtnText_hover;
    }

    img {
      opacity: 1;
    }
  }
}
.btnStyle + .btnStyle {
  @media screen and (min-width : $viewTablet+px) {
    margin-left: 5px;
  }
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    margin-top: 5px;
  }
}

.btnStyle.btn2 {
  background: $colorBtn;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    max-width: inherit;
  }
  &:before {
    content: none;
  }
  &:after {
    width: 0;
    height: 0;
    transform: none;
    border-width: 5px 0 5px 5px;
    border-style: solid;
    border-color: transparent transparent transparent $colorBtnText;
  }
  &:hover {
    background: $colorBtn_hover;
    &:after {
      border-color: transparent transparent transparent $colorBtnText_hover;
    }
  }
}

.btnStyle.-wd100 {
  max-width: 100%!important;
}


///-----------------グーグルマップ・YouTube//

.googlemapWrapper, .youtubeWrapper {
  position: relative;
  width: 100%;
  min-height: 225px;
  padding-top: 53%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }//ここで高さ調整//
}

///-----------------新着情報//
.newsContent {
  position: relative;
  overflow: hidden;
  padding: 0;
  background: none;

  dl {
    width: 100%;
    @media screen and ( max-width : ($viewTablet - 1)+px ) {
      dt, dd {
        float: none;
        width: 100%;
        padding-left: 0;
      }
      dt {
        padding-bottom: 0;
      }
      dd {
        padding-top: 0;
        border-bottom: 1px solid lighten($colorText, 20%);
        &:after {
          content: none;
        }
      }
    }

    dt,dd {
      display: block;
      padding: .6em .8em;
      text-align: left;
      letter-spacing: 0.08em;
    }
    dt {
      float: left;
      clear: left;
      width: 17%;
      // padding: 1em 1.5em;
      padding-left: 1.5em;
    }
    dd {
      position: relative;
      float: left;
      width: 83%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 120vw;
        height: 1px;
        margin: auto;
        transform: translateX(-15%);
        border-bottom: 1px solid rgba($colorText, .2);
      }
    }
  }
}

.newsBtn {
  position: absolute;
  top: 15%;
  right: 3%;
  display: inline-block;
  margin: auto;

  a {
    display: inline-block;
    padding: .5em 1em;
    font-size: 14px;
    line-height: 1;
    transition: .4s;
    text-decoration: none;
    color: $colorBtnText;
    border: 1px solid $colorBtn;
    background: $colorBtn;

    &:after {
      content: '＞';
      margin-left: .5em;
    }

    &:hover {
      transition: .4s;
      color: $colorBtn;
      background: none;
    }
  }
}

//----------------- パンくずリスト//

.breadcrumb {
  margin-top: 1%;
  margin-bottom: 5%;
  margin-left:0;
  padding-left:0;
  line-height: 1.0;
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    display: none;
  }

  li {
    display:inline;
    font-size: 13px;
    list-style: none;

    &:not(:last-child):after {
      content: '>';
      padding: 0 4px;
      font-size: 10px;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:last-child {
      color: $color01;
    }
  }
}

///-----------------リスト//

.ul00 {
  margin: 0 auto;
  padding-left: 1.5em;
  list-style-type: disc;

  > li {
    margin-bottom: 5px;
    text-align: left;
  }
}

.ul01 {
  margin: 0 auto;

  > li {
    position: relative;
    margin-bottom: 5px;
    padding: 0 0 0 1.5em;
    text-align: left;

    &:before {
      content: "";
      position: absolute;
      top: .5em;
      // bottom: 0;
      left: 3px;
      width: 10px;
      height: 10px;
      margin: auto;
      border-radius: 50%;
      background: $color02;
    }
  }
  &.-square li {
    &:before {
      border-radius: 0;
    }
  }
}

//-----------------チェックリスト/

.checkList {
  margin: 0 auto;

  > li {
    position: relative;
    margin-bottom: 5px;
    padding: 0 0 5px 15px;

    &:before , &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
    }

    &:before {
      width: 10px;
      height: 10px;
      border: 1px solid lighten($colorText, 10%);
      background: transparent;
    }
    &:after {
      top: -7px;
      width: 15px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid $color01;
      border-left: 2px solid $color01;
    }
  }
}

///-----------------フロートリスト//

ul.-floatList {
  overflow: hidden;
  zoom: 1;
  width: 100%;
  margin: 0 auto;

  > li {
    float: left;
    margin-bottom: 15px;
  }
  > li + li {
    margin-left: 15px;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    ul.-floatList {
      > li {
        float: none;
      }
      > li + li {
        margin-left: auto;
      }
    }
  }
}

///-----------------定義リスト//

.dl01 {
  overflow: hidden;
  zoom: 1;
  margin: 0 auto;

  dt {
    position: relative;
    margin-bottom: 0;
    padding: 0 0 0 1em;

    &:before {
      content: "";
      position: absolute;
      top: .8em;
      left: 3px;
      width: 10px;
      height: 3px;
      margin: auto;
      background: $color02;
    }
  }
  dt + dd {
    position: relative;
    margin-bottom: 5px;
    padding: 0 0 0 1.5em;
  }
}

///-----------------横並び定義リスト//

dl.-floatList {
  clear: both;
  width: 100%;

  dt, dd {
    display: block;
    text-align: left;
  }

  dt {
    float: left;
    clear: left;
    width: 180px;
  }
  dd {
    display: block;
    float: left;
    width: 80%;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    dl.-floatList {
      dt, dd {
        float: none;
        width: 100%;
      }
      dt {
        padding-bottom: 0;
      }
      dd {
        padding-top: 0;
        padding-left: 1.5em;
      }
    }
  }
}

///-----------------ボーダリスト//

.-borderList {
  width: 100%;
  margin: 0 auto;
  list-style: none;

  > li {
    position: relative;
    margin-bottom: 5px;
    padding: 0 0 5px 15px;
    border-bottom: 1px solid $color01;
  }
}

///-----------------数字リスト//

.ol00 {
  margin: 0 auto;
  padding-left: 1.5em;
  list-style-type: decimal;

  > li {
    margin-bottom: 5px;
  }
}

.ol01 {
  margin: 0 auto;
  list-style-type: none;
  counter-reset: number 0;

  > li {
    position: relative;
    margin-bottom: 5px;
    padding: 0 0 0 1.6em;

    &:before {
      content: "" counter(number);
      position: absolute;
      top: 3px;
      left: 3px;
      width: 20px;
      height: 20px;
      margin: 0;
      padding: .2em 0 0;
      font-size: 95%;
      line-height: 1;
      counter-increment: number 1;
      text-align: center;
      color: $colorTextReversal;
      border: 0;
      border-radius: 50%;
      background: $color01;
    }
  }
}

///-----------------Q&A//

.faqWrapper {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  > ul {
    width: 100%;
    padding: 2.5em 0;
    background: none;

    > li {
      position: relative;
      //border-bottom: 2px solid #cdcdcd;//
      margin-bottom: 15px;
      padding: 8px 0 0 60px;
      list-style-type: none;
      list-style-image: none;
      text-align: left;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 44px;
        height: 44px;
        margin: auto;
        padding: 0;
        font-size: 26px;
        font-weight: normal;
        line-height: 1.7;
        text-align: center;
        vertical-align: text-top;
        color: $colorTextReversal;
        border-radius: 50%;
      }
      &:nth-child(odd) {
        font-size: 120%;
        font-weight: bold;
        color: $color01;

        &:before {
          content: 'Q';
          background: $color01;
        }
      }
      &:nth-child(even) {
        margin-bottom: 30px;
        padding-bottom: 2em;
        border-bottom: 1px dotted rgba($color01, .25);

        &:before {
          content: 'A';
          background: $color02;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

///-----------------テーブル//

table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border: 0;
  background: none;

  th, td {
    padding: 15px 10px;
    line-height: 1.5;
    vertical-align: middle;
  }
}

.table01 {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border: 1px solid lighten($color01, 0);

  th, td {
    border: 1px solid lighten($color01, 0);
    background: #fff;
  }
  th {
    font-weight: bold;
    text-align: center;
    color: $colorTextReversal;
    background-color: rgba($color01, .75);
  }
  thead {
    th {
      background-color: rgba($color01, .75);
    }
  }
}

///-----------------下線・上線のみ//

.-borderTable {
  tr {
    border-bottom:1px solid $color01;
  }
  th {
    text-align: left;
  }
}

///-----------------行の色が互い違い//

.-stripeTable {
  tr:nth-child(odd) {
    background: lighten($color01, 35%);
  }
  tr:nth-child(even) {
    background: lighten($color01, 45%);
  }
}

///-----------------診療時間//

.timeTable {
  // border: 1px solid #cccccc;
  font-size: 1.1rem;

  thead {
    th, td {
      // border-bottom: 1px solid #cccccc;
      padding: 8px;
      text-align: center;
      letter-spacing: 0.27em;
      color: $colorTextReversal;
      background: lighten($color01, 0);
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $color01;
    }
    th, td {
      padding: 10px;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      // border: 1px solid #cccccc;
    }
    th {
      width: 160px;
      letter-spacing: 0.15em;
    }
    td {
      color: $color01;
      span {
        // font-weight: normal;
        color: $colorText;
      }
    }
  }
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    .Xscroll {
      position: relative;
      overflow-x: scroll;
      width: 100%;
      padding-bottom: 3em;

      > table {
        width: $containerSize+px;
        table-layout: fixed;
      }

      &:after {
        content: '※横にスクロールできます';
        font-size: 90%;
        font-weight: normal;
        color: red;
      }
    }
  }
}

///-----------------価格//

.priceTable {
  tr {
    border-bottom: 1px solid #ddd;
  }
  th, td {
    position: relative;
    padding: 5px;
    font-size: 110%;
    font-weight: bold;
  }
  th {
    text-align: left;
    span {
      font-size: 100%;
      font-weight: normal;
    }
  }
  td {
    width: 30%;
    text-align: right;
    span {
      font-size: 150%;
    }
  }
}

///-----------------縦積み//

@media screen and  ( max-width : ($viewTablet - 1)+px ) {
  .-resTable {
    th, td {
      display: block;
      width: 100%!important;
      padding: .5em .5em;
    }
  }
  tr {
    padding-bottom: .5em;
  }
}

///-----------------CSS図形//

//丸に三角//
.-circleArrow {
  position: relative;
  margin-right: .5em;
  padding: 0 0 0 1em;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 2px;
    width: 0;
    height: 0;
    margin: auto;
    border-width: 3px 0 3px 5.2px;
    border-style: solid;
    border-color: transparent transparent transparent $colorTextReversal;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 15px;
    margin: auto;
    border-radius: 50%;
    background: $color01;
  }
}

//数字リセット（親要素以上に指定）//
.countReset {
  overflow: hidden;
  zoom: 1;
  counter-reset: number 0;
}


//レスポンシブ丸//

.-circle {
  position: relative;
  overflow: hidden;
  padding: 50%;
  border-radius: 50%;
  background-color: $color01;

  > span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: -.5em 0 0;
    line-height: 1;
    text-align: center;
    color: $colorText;
  }
  > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: cover;
    object-position: center;
  }
}

//斜め縞//
.-bgSlash {
  &:after {
    @include bgSlash();
  }
}

//-----------------汎用クラス//

.-color01 {color: $color01+!important;}
.-color02 {color: $color02+!important;}
.-color03 {color: $color03+!important;}
.-color04 {color: $color04+!important;}
.-textRed { color: red!important}

.-highlight {
  background: $color02;
}

.-textIndent {text-indent: 1em;}

.-textLeft {text-align: left!important;}
.-textCenter {text-align: center!important;}
.-textRight {text-align: right!important;}

.-textTop { vertical-align: top!important;}
.-textMiddle { vertical-align: middle!important;}
.-textBottm { vertical-align: bottom!important;}

.-textLarge {font-size: 120%!important;}
.-textXLarge {font-size: 150%!important;}
.-textXXLarge {font-size: 200%!important;}
.-textSmall {font-size: 80%!important;}
.-textXSmall {font-size: 50%!important;}
.-bold {font-weight: bold!important;}

.-textMarker {
  line-height: 1.3!important;
  background: linear-gradient(transparent 65%, $color04 65%)!important;
}

.-textShadow {
  text-shadow: 1px 1px 2px rgba(#000, .8), 1px 1px 4px rgba(#000, .4);
}

.-textEdge {
  text-shadow:1px 1px 0 #fff, -1px -1px 0 #fff,
    -1px 1px 0 #fff, 1px -1px 0 #fff,
    0px 1px 0 #fff,  0 -1px 0 #fff,
    -1px 0 0 #fff, 1px 0 0 #fff;
}

.-textSidebar {
  position: relative;
  display: table;
  padding: 0 1.5em;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    display: block;
    width: 1em;
    height: 2px;
    margin: auto;
    background: #5e3491;
  }
  &:after {
    right: 0;
    left: auto;
  }
}

@media screen and  ( min-width : $viewTablet+px ) {
  .pc-textCenter {text-align: center!important;}
  .pc-textRight {text-align: right!important;}
  .pc-textLeft {text-align: left!important;}
}

@media screen and  ( max-width : ($viewTablet - 1)+px ) {
  .sp-textCenter {text-align: center!important;}
}

img.-alignright {
  float:right;
  margin:0 0 10px 2%;
}
img.-alignleft {
  float:left;
  margin:0 2% 10px 0;
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    img.-alignright,
    img.-alignleft {
      display:block;
      float: none;
      margin:0 auto 20px;
    }
  }
}

@for $i from 0 through 10 {
  .-mb#{$i * 5} {margin-bottom:#{$i * 5}px!important;}
}
.-pd0 {padding: 0em!important;}
.-pd1 {padding: 1em!important;}

.-mincho {
  font-family: $fontMincho!important;
}

.-gothic {
  font-family: $fontGothic!important;
}

.-marugo {
  font-family: $fontMarugo!important;
}

.-meiryo {
  font-family: $fontMeiryo!important;
}

.-inversion {
  h2,h3,h4,h5 {
    color: $colorTextReversal;
    &:after,&:before {
      border-color: $colorTextReversal;
      background-color: $colorTextReversal;
    }
    span {
      color: $colorTextReversal;
    }
  }
  p, li, td, th, a, figcaption {
    color: $colorTextReversal;
  }
}

h2.-textLeft {
  &:after {
    right: auto;
  }
}
h2.-pc-textLeft {
  &:after {
    @media screen and  ( min-width : 768px ) {
      right: auto;
    }
  }
}

///-----------------グリッドタイル//

.gridBox {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  column-gap: 0;

  @for $i from 2 through 10 {
    &.-blocks_#{$i} {
      column-count: #{$i};

      // @media screen and  ( max-width : ($viewTablet - 1)+px ) {
      // 	column-count: 2;
      // }
      // @media screen and  ( max-width : ($viewSP - 1)+px ) {
      // 	column-count: 1;
      // }
    }
  }

  > figure {
    max-width: 100%;
    margin: 0;
    padding: 1%;
    break-inside: avoid;
  }
}

///-----------------フロー//

.flowWrapper {
  width: 100%;
  margin: auto;
  padding: 0;
  counter-reset: number 0;

  > .flowContents {
    position: relative;
    display: block;
    margin: 10px auto 50px;
    padding: 0;

    &:last-child {
      margin: 10px auto;
    }
    &:not(:last-child):after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -40px;
      left: 0;
      display: block;
      width: 0;
      height: 0;
      margin: auto;
      border-width: 30px 25px 0 25px;
      border-style: solid;
      border-color: $color01 transparent transparent transparent;
    }
  }
}

.flowTitle {
  span {
    margin-right: .5em;
    font-size: 1.8rem;
    line-height: 1;
    vertical-align: baseline;
    letter-spacing: 0;
    color: $color01;

    &:after {
      content: "0" counter(number);
      counter-increment: number 1;
    }
  }
}


.flowWrapper.-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .flowContents {
    margin: 0;
    padding: 1.5em 1%;
    flex: 1;

    &:not(:last-child) {
      margin-right: 4%;

      &:after {
        top: 0;
        right: -19%;
        bottom: 0;
        left: auto;
        margin: auto;
        border-width: 50.5px 0 50.5px 25px;
        border-color: transparent transparent transparent $color01;
      }
    }
  }
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    .flowWrapper.-horizontal {
      display: block;

      .flowContents {
        display: block;
        width: 100%;
        margin: 10px auto;

        &:not(:last-child) {
          margin-right: auto;
          margin-bottom: 50px;

          &:after {
            content: '';
            position: absolute;
            top: auto;
            right: 0;
            bottom: -65px;
            left: 0;
            display: block;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

///-----------------拡大オーバー//

.scaleBox {
  position: relative;
  display: table;
  margin: auto;
  table-layout: fixed;

  > span {
    display: table;
    overflow: hidden;
    table-layout: fixed;

    img {
      transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
  }
  > a {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  &:hover {
    > span img {
      transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
      transform: scale(1.05);
    }
  }
}

///-----------------ボーダーボックス//

$color_border: $colorTextReversal;
@mixin border_wrap_common01() {
  content: "";
  position: absolute;
  transform: scale(0);
  opacity: 0;
}
@mixin border_wrap_common02() {
  display: block;
  transition: 1.5s;
  transform: scale(1);
  opacity: 1;
}
.borderBox {
  > div {
    position: relative;
    overflow: auto;
    padding: 3%;
    &:before, &:after {
      @include border_wrap_common01();
      top: 0;
      left: 0;
    }
    &:before {
      width: 1px;
      height: 100%;
      background: $color_border;
    }
    &:after {
      width: 100%;
      height: 1px;
      background: $color_border;
    }
    > div {
      &:before, &:after {
        @include border_wrap_common01();
        right: 0;
        bottom: 0;
      }
      &:before {
        width: 1px;
        height: 100%;
        background: $color_border;
      }
      &:after {
        width: 100%;
        height: 1px;
        background: $color_border;
      }
    }
  }
  &.active {
    > div {
      &:before, &:after {
        @include border_wrap_common02();
      }
      > div {
        &:before, &:after {
          @include border_wrap_common02();
        }
      }
    }
  }
}

///-----------------プルダウンコンテンツ//

.pullDown {
  position: relative;
  width: 100%;

  > .pull-inner {
    display: none;
    width: 100%;
    padding-top: 1em;
  }

  > p:first-child {
    // font-size: 17px;
    // font-weight: bold;
    // letter-spacing: 0.1em;
    // padding: 1.3em;
    position: relative;
    display: table;
    margin: 0;
    padding-right: 8%;
    cursor: pointer;
    // color: $colorTextReversal;
    text-align: left;
    background: none;

    img {
      @media screen and  ( max-width : 767px ) {
        width: 80%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 5%;
      bottom: 0;
      width: 37px;
      height: 37px;
      margin: auto;
      transition: .4s;
      background: url(/wp-content/uploads/2019/07/lp1_btn2.png) no-repeat center / 100%;
      @media screen and  ( max-width : 767px ) {
        width: 30px;
      }
    }
    &.active {
      &:after {
        content: '';
        transition: .4s;
        transform: rotate(180deg);
      }
    }
  }
}

@if $spMode == false {
  @media screen and  ( max-width : ($viewTablet - 1)+px ) {
    .sp_pull {
      > .pull-inner {
        display: none;
        width: 100%;
      }
      > p:first-child {
        position: relative;
        margin: 0;
        padding: 1.3em;
        font-size: 17px;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        letter-spacing: 0.1em;
        color: $colorTextReversal;
        background: $color01;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 5%;
          bottom: 0;
          width: 8px;
          height: 8px;
          margin: auto;
          transition: .4s;
          transform: rotate(45deg);
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
        &.active {
          &:after {
            content: '';
            transition: .4s;
            transform: rotate(225deg);
          }
        }
      }
    }
  }
}

///-----------------タブコンテンツ//

.tab-inner {
  width: 100%;
  margin: auto;
}

.tabContent {
  display: none;
  padding: 1.5em .5em;
  transition: .4s;
}

.tabButton {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;

  > li {
    position: relative;
    padding: .5em;
    line-height: 1;
    cursor: pointer;
    transition: .4s;
    text-align: center;
    color: #969696;
    border: 1px solid $color01;
    border-bottom: 0;
    background: $color01;
    flex: 1;

    &:hover, &.active {
      -webkit-transition: .4s;
      transition: .4s;
      // color: #333;
      // border-color: #ddd;
      // background: #fff;
    }
  }
}


///-----------------アニメーション//

.scaleText {
  margin-top: 1em;
  animation: scaletext 2s ease 0s infinite normal;
}

@keyframes scaletext {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.scrollIn,.loadIn {
  transition: all 600ms;
  opacity: 0;
  &.up { transform: translate(0, 150px)}
  &.down { transform: translate(0, -150px)}
  &.right { transform: translate(150px, 0)}
  &.left { transform: translate(-150px, 0)}
  &.scale { transform: scale(0)}
  &.bound { transform: translate(0, -150px)}
  &.arcRight { transform: rotate(30deg);transform-origin: center 150%;}
  &.arcLeft { transform: rotate(-30deg);transform-origin: center 150%;}
  &.para {
    transform: translate(0, 150px);
    opacity: 1;
    > * {
      transform: translate(0, 150px);
    }
  }


  &.active {
    transition: all 600ms ease-out;
    transform: none;
    opacity: 1;
    &.bound {
      animation: bound 1.2s ease 0s 1 forwards;
    }
    &.para {
      > * {
        transition: all 1000ms;
        transition-delay: 50ms;
        transform: none;
      }
    }
  }
}

.scroll {
  &.leftSlide {
    position: relative;
    display: table;
    &:before {
      content: ''!important;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      margin: auto;
      transform-origin: right;
      background: $color03;
    }
    > * {
      margin: auto;
      padding: 0;
      opacity: 0;
    }

  }
  &.active {
    &:before {
      animation: leftSlide 1s ease-in 0s 1 forwards;
    }
    > * {
      transition-delay: .6s;
      opacity: 1;
    }
  }
}

@for $i from 1 through 30 {
  .scrollIn.active.each ~ .scrollIn.active.each:nth-of-type(#{$i}),
  .loadIn.active.each ~ .loadIn.active.each:nth-of-type(#{$i}) {
    transition-delay: #{$i * 100}ms;
  }
  .scroll.active.leftSlide.each {
    &:nth-of-type(#{$i}):before {
      animation-delay: #{$i * 200}ms;
    }
    > *:nth-of-type(#{$i}) {
      transition-delay: #{$i * 1200}ms;
    }
  }
  .bound.active.each ~ .bound.active.each:nth-of-type(#{$i}) {
    transition-delay: none!important;
    animation-delay: #{$i * 100}ms!important;
  }
}

@keyframes bound {
  100%, 20%, 50%, 80% {
    transform: translateY(0) scale(1);
  }
  // 10%, 30% {
  //   transform: translateY(0) scale(1.1,0.8);
  // }
  0%, 40% {
    transform: translateY(-20%) scale(1);
  }
  60% {
    transform: translateY(-10%) scale(1);
  }
}


@keyframes leftSlide {
  0% {
    width: 0;
  }
  50% {
    width: 105%;
    transform: scaleX(1);
  }
  70% {
    width: 105%;
    transform: scaleX(1);
  }
  100% {
    width: 105%;
    transform: scaleX(0);
  }
}

//----------------- オープニング画面/
//
// .opening-container {
//   overflow: hidden;
//   width: 100%;
//   position: relative;
//   background: #fff;
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   display: inline-block;
//   z-index: 99999;
//
// 	.openingContents {
// 	  width: calc(100% + 17px); //100vhに収まる場合は100%でOK
// 	  height: 100vh;
// 	  position: fixed;
// 	  top: 0;
// 	  z-index: 9999;
// 	  overflow-y: scroll; //100vhに収まる場合はhiddenでOK
// 	  background: #fff;
// 	  opacity: 0;
// 	   width: 50%;
// 	}
// }
