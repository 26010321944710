@charset "utf-8";

// ---------------------------------------------------------------------------
// Media Queries
// ---------------------------------------------------------------------------
@mixin mq($breakpoint: medium, $rule: max, $addition: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == min and $addition {
    $breakpoint: $breakpoint + .0625;
  }
  @media (#{$rule}-width: $breakpoint) {
    @content;
  }
}

// ---------------------------------------------------------------------------
// Clearfix
// ---------------------------------------------------------------------------
@mixin clearfix() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// ---------------------------------------------------------------------------
// 横幅をコンテンツ幅にして中央寄せ
// ---------------------------------------------------------------------------
@mixin cwLayout() {
  max-width: $contentsW;
  margin-right: auto;
  margin-left: auto;
}

// ---------------------------------------------------------------------------
// Hover時に要素を透明化
// ---------------------------------------------------------------------------
@mixin hoverOpacityEf($time: .5s, $opacity: .5) {
  a {
    transition: $time;
    &:hover {
      opacity: $opacity;
    }
  }
}


///-----------------CSSパターン//


@mixin bgCheck2($tartanColor:#000 ,$checkSize: 50px 50px) {
  background-image:
    linear-gradient(to bottom,
    rgba($tartanColor,0.5) 0%, rgba($tartanColor,0.5) 30%, transparent 30%, transparent 33%,
    rgba($tartanColor,0.5) 33%, rgba($tartanColor,0.5) 40%, transparent 40%, transparent 80%,
    rgba($tartanColor,0.3) 80%, rgba($tartanColor,0.3) 96%, transparent 96%, transparent 100%),
    linear-gradient(to right,
    rgba($tartanColor,0.5) 0%, rgba($tartanColor,0.5) 30%, transparent 30%, transparent 33%,
    rgba($tartanColor,0.5) 33%, rgba($tartanColor,0.5) 40%, transparent 40%, transparent 80%,
    rgba($tartanColor,0.3) 80%, rgba($tartanColor,0.3) 96%, transparent 96%, transparent 100%);
  background-repeat: repeat;
  background-size: $checkSize;
}

@mixin bgBorder($borderColor: #fff, $borderHeight: 5) {
  background-image: linear-gradient($borderColor, $borderColor 50%, transparent 50%, transparent);
  background-repeat: repeat;
  background-size: $borderHeight+px $borderHeight+px;
}

@mixin bgDotted($dotsColor: #fff, $dotsSize: 10) {
  background-image: radial-gradient(circle farthest-side, $dotsColor, $dotsColor 45%, transparent 55%, transparent);
  background-repeat: repeat;
  background-size: $dotsSize+px $dotsSize+px;
}

@mixin bgStripe($stripeColor: #fff, $stripeWidth: 5) {
  background-image: linear-gradient(to right, $stripeColor, $stripeColor 50%, transparent 50%, transparent);
  background-repeat: repeat;
  background-size: $stripeWidth+px $stripeWidth+px;
}

@mixin bgCheck($checkColor: #fff, $checkWidth: 10) {
  background-image: linear-gradient($checkColor, $checkColor 50%, transparent 50%, transparent),
    linear-gradient(to right, $checkColor, $checkColor 50%, transparent 50%, transparent);
  background-repeat: repeat;
  background-size: $checkWidth+px $checkWidth+px;
}

@mixin bgSlash($slashColor: #fff, $slashWidth: 5) {
  background-image: linear-gradient(to right bottom, $slashColor, $slashColor 24%, transparent 26%, transparent 49%, $slashColor 51%, $slashColor 74%, transparent 76%, transparent);
  background-repeat: repeat;
  background-size: $slashWidth+px $slashWidth+px;
}
